import NextError from 'next/error';
import Layout from '@layouts/Layout';
import { ThemeProvider } from 'theme-ui';
import { theme } from '#theme';
import { SpacingContext } from '@lib/hooks';
import { getPageData, PageData } from '#utils';
import { PageSpacing } from '@lib/constants';
import type { NextPage, GetStaticProps } from 'next';
import type { ParsedUrlQuery } from 'querystring';

interface Props {
  page?: PageData;
}

interface Params extends ParsedUrlQuery {
  readonly slug: Array<string>;
}

const FourOFourPage: NextPage<Props> = ({ page }) => {
  if (!page) {
    return (
      <NextError
        statusCode={500}
        title="No matching entry for 404 page found in Contenful"
      />
    );
  }

  const spacingObj = {
    pageSpacing: PageSpacing[page?.pageContent.pageSpacing || 'Loose'],
    heroSpacing: page?.pageContent.heroSpace ?? true,
    footerSpacing: page?.pageContent.footerSpace ?? true,
  };

  const {
    pageContent: {
      bodyContentCollection: { items: bodyContent = [] } = {},
      variant = 'Default',
      ...layoutPropsFromPageContent
    } = {},
    ...layoutPropsFromPage
  } = page;

  return (
    <ThemeProvider theme={theme}>
      <SpacingContext.Provider value={spacingObj}>
        <Layout
          variant={variant}
          {...layoutPropsFromPageContent}
          {...layoutPropsFromPage}
          bodyContent={bodyContent}
        />
      </SpacingContext.Provider>
    </ThemeProvider>
  );
};

export const getStaticProps: GetStaticProps<Props, Params> = async () => {
  const page = await getPageData('error404');

  return {
    props: { page },
  };
};

export default FourOFourPage;
